/* footer */

.Footer-container{
    position: relative;
    
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.logo-f>img{
    width: 10rem;
}
.Footer-container>:nth-child(3)
{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}
.Footer-container>:nth-child(4)
{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}

/* footer */


/* header */
.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color:white;
}
.header-menu>li:hover{
    color: var(--orange);
    cursor: pointer
}

@media screen and (max-width: 767px) {
    .header>:nth-child(2)
    {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
      position: absolute;
        flex-direction: column;
        background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 4px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
        display: flex;
        align-items: center;
        z-index: 99;
        justify-content: center;
        padding: 2rem;
        padding-top: 0;
    }
  
}
/* header */

/* hero */
.hero {
    display: flex;
    justify-content: space-between;
  }
  .left-h {
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .right-h {
    background-color: #f48916;
    flex: 1;
    position: relative;
  }
  .hero-blur {
    width: 22rem;
    height: 30rem;
    left: 0;
  }
  
  .the-best-ad {
    margin-top: 4rem;
    background-color: #393d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
  }
  .the-best-ad > span {
    z-index: 2;
  }
  .the-best-ad > div {
    position: absolute;
    background-color: #f48916;
    width: 5.4rem;
    height: 80%;
    left: 8px;
    z-index: 1;
    border-radius: 3rem;
  }
  
  .hero-text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
  }
  
  .hero-text > div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
  }
  
  .figures {
    display: flex;
    gap: 2rem;
  }
  .figures > div {
    display: flex;
    flex-direction: column;
  }
  .figures > div > span:nth-of-type(1) {
    color: white;
    font-size: 2rem;
  }
  .figures > div > span:nth-of-type(2) {
    color: var(--gray);
    text-transform: uppercase;
  }
  
  .hero-buttons {
    display: flex;
    gap: 1rem;
    font-weight: normal !important;
  }
  .hero-buttons > :nth-child(1) {
    color: white;
    background-color: var(--orange);
    width: 8rem;
  }
  .hero-buttons > :nth-child(2) {
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
  }
  
  /* right side */
  .right-h > .btn {
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
  }
  
  .heart-rate {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
  }
  .heart-rate > img {
    width: 2rem;
  }
  .heart-rate > :nth-child(2) {
    color: var(--gray);
  }
  .heart-rate > :nth-child(3) {
    color: white;
    font-size: 1.5rem;
  }
  
  .hero-image {
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
  }
  .hero-image-back {
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;
  }
  
  .calories {
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 32rem;
    right: 28rem;
  }
  .calories > img {
    width: 3rem;
  }
  .calories > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .calories > div > :nth-child(1) {
    color: var(--gray);
  }
  .calories > div > :nth-child(2) {
    color: white;
    font-size: 1.5rem;
  }
  
  @media screen and (max-width: 768px) {
    .hero {
      flex-direction: column;
    }
    .hero-blur
    {
      width: 14rem;
    }
    .the-best-ad {
      margin-top: 0;
      font-size: small;
      align-self: center;
      transform: scale(0.8);
    }
    .hero-text {
      font-size: xx-large;
      align-items: center;
      justify-content: center;
    }
    .hero-text > div:nth-of-type(3) {
      font-size: small;
      font-weight: 200;
      letter-spacing: 1px;
      text-align: center;
    }
    .hero-buttons{
     justify-content: center;
    }
    .figures > div > span:nth-of-type(1) {
      font-size: large;
    }
    .figures > div > span:nth-of-type(2) {
      font-size: small;
    }
    .right-h {
      position: relative;
      background: none;
    }
    .heart-rate {
      left: 1rem;
      top: 2rem;
    }
    .calories {
      position: relative;
      top: 5rem;
      left: 2rem;
    }
    .calories>img{
      width: 2rem;
    }
    .calories > div > :nth-child(2) {
      color: white;
      font-size: 1rem;
    }
    .hero-image {
      position: relative;
      width: 15rem;
      left: 7rem;
      top: 4rem;
      align-self: center;
    }
    .hero-image-back{
      width: 15rem;
      left: 2rem;
      top: 0rem;
    }
  }
  
/* hero */

/* join */
.Join {
    padding: 0 2rem;
    display: flex;
    gap: 10rem;
}
.left-j
{
    color:white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border:2px solid var(--orange);
    border-radius: 20%;
    margin:-10px 0;
}
.left-j>div{
    display: flex;
    gap: 1rem;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray)
}
::placeholder{
    color: var(--lightgray)
}
.btn-j{
    background-color: var(--orange);
    color: white
}

@media screen and (max-width: 768px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .right-j{
        padding: 2rem;
    }
}
/* join */

/* plains */
.plans-container {
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
  }
  .plans-blur-1{
      width: 32rem;
      height: 23rem;
      top: 6rem;
      left: 0rem;
  }
  .plans-blur-2{
      width: 32rem;
      height: 23rem;
      top: 10rem;
      right: 0rem;
  }
  
  .plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
  
  .plan {
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
  }
  .plan:nth-child(2){
      background: var(--planCard);
      transform: scale(1.1);
  
  }
  .plan>svg{
      fill: var(--orange);
      width: 2rem;
      height: 2rem;
  }
  .plan>:nth-child(2){
      font-size: 1rem;
      font-weight: bold;
  }
  .plan>:nth-child(3){
      font-size: 3rem;
      font-weight: bold;
  }
  .plan>:nth-child(5){
      font-size: 0.8rem;
  }
  .features{
      display: flex;
      flex-direction: column;
      gap: 1rem;
  }
  
  .feature{
      display: flex;
      align-items: center;
      gap: 1rem;
  }
  .feature>img{
      width: 1rem;
  }
  .plans>:nth-child(2)>svg{
      fill: white;
  }
  .plans>:nth-child(2)>button{
      color: orange;
  }
  
  
  @media screen and (max-width: 768px) {
      .plans-container>.programs-header{
          font-size: x-large;
      }
      .plans{
          flex-direction: column;
      }
      .plans>:nth-child(2)
      {
          transform: none;
      }
  }

/* plains */

/* program */
.Programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
  }
  .programs-header {
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
  }
  
  .prgoram-categories {
    display: flex;
    gap: 1rem;
  }
  
  .category {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    color: white;
    flex: 1;
    z-index: 2;
    justify-content: space-between;
  }
  .category::before {
    content: '';
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
background-image:linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    transition: all 0.5s ease; 
    clip-path: circle(25.5% at 100% 0);
      }
    .category:hover::before {
      clip-path: circle(70.7% at 50% 50%)

    }
  .category > :nth-child(1) {
    width: 2rem;
    height: 2rem;
    fill:white
  }
  .category > :nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
  }
  .category > :nth-child(3) {
    font-size: 0.9rem;
    line-height: 25px;
  }
  .join-now{
      display: flex;
      align-items: center;
      /* justify-content: center; */
      gap: 2rem;
  }
  .join-now>img{
      width: 1rem
  }
  
  @media screen and (max-width: 767px) {
    .programs-header{
      flex-direction: column;
      gap: 1rem;
      font-size: x-large;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
    }
    .prgoram-categories{
      flex-direction: column;
    }
  }
  
/*  */

/* reason */
.Reasons {
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
  }
  .left-r {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
  }
  .left-r > img {
    object-fit: cover;
  }
  .left-r > :nth-child(1) {
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
  }
  .left-r > :nth-child(2) {
    grid-column: 2/4;
    width: auto;
    height: 16rem;
  }
  .left-r > :nth-child(3) {
    grid-row: 2;
    grid-column: 2/3;
    width: 14rem;
  }
  .left-r > :nth-child(4) {
    grid-row: 2;
    grid-column: 3/4;
    width: 10rem;
    height: 11.2rem;
  }
  
  /* right side */
  .right-r {
    flex: 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
  .right-r > span {
    font-weight: bold;
    color: var(--orange);
  }
  .right-r > div {
    color: white;
    font-size: 3rem;
    font-weight: bold;
  }
  .details-r {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .details-r > div {
    display: flex;
    font-size: 1rem;
    gap: 1rem;
  }
  .details-r > div > img {
    width: 2rem;
    height: 2rem;
  }
  
  .partners {
    display: flex;
    gap: 1rem;
  }
  .partners > img {
    width: 2.5rem;
  }
  
  @media screen and (max-width: 768px) {
    .Reasons {
      flex-direction: column;
    }
    .left-r {
      grid-auto-rows: auto;
      overflow: hidden;
    }
    .left-r > :nth-child(1) {
      width: 7rem;
      height: 17rem;
    }
    .left-r > :nth-child(2) {
      width: 15rem;
      height: 10rem;
    }
    .left-r > :nth-child(3) {
      width: 7rem;
    }
    .left-r > :nth-child(4) {
      width: 7rem;
      height: 6rem;
    }
  }
  
/* reason */

/* testimonial */
.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.left-t{
    display: flex;
    flex: 1.1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-t>:nth-child(2),
.left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}
.right-t{
    flex:1;
    position: relative;
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.right-t>:nth-child(1)
{
    position: absolute;
    width: 17rem;height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
}
.right-t>:nth-child(2)
{
    position: absolute;
    width: 17rem;height: 19rem;
    right: 7rem;
    top: 4rem;
    background:var(--planCard);
}
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer
}

@media screen and (max-width: 767px) {
    .testimonials{
        flex-direction: column;
    }
    .left-t>:nth-child(2)
    ,.left-t>:nth-child(3){
        font-size: xx-large;
    }
    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .right-t>div{
        position:relative;
        display:none;
    }
    .right-t>img{
        top: 0;
        right: 0;
        position:relative;
        align-self: center;
    }
    .right-t>:last-child{
        display: block;
        bottom: 0;
        left: 0;
    }
}
/* testimonial */